import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import favicon from '../images/favicon.ico'

const HelmetComponent = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/x-icon',
              href: `${favicon}`,
            },
          ]}
        />
      </>
    )}
  />
)

export default HelmetComponent
